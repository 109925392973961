import React, { useContext } from 'react'
import styled from 'styled-components'
import LongPress from 'react-long'

import Context from '../../context'
import {
  Button,
  Label,
  Input,
  // Flex,
  Day as DayElement
  // Drawer
} from '../library'

const days = ['Su', 'M', 'Tu', 'W', 'Th', 'F', 'Sa']

const EditHabit = () => {
  const { habitToEdit, setHabitToEdit, habitsDispatch } = useContext(Context)

  const close = () => {
    setHabitToEdit(null)
  }

  const onDelete = () => {
    habitsDispatch({ type: 'remove', habit: habitToEdit })
    close()
  }

  const onDayToggle = day => {
    habitsDispatch({ type: 'toggle_day', habit: habitToEdit, day })
  }

  const onTimeUpdate = event => {
    const time = event.target.value
    habitsDispatch({ type: 'update_time', habit: habitToEdit, time })
  }

  const renameHabit = event => {
    const name = event.target.value
    habitsDispatch({ type: 'rename', habit: habitToEdit, name })
  }

  // const Reset = () => (
  //   <Button
  //     appearance="icon"
  //     onClick={function() {
  //       localStorage.clear()
  //       window.location.reload()
  //     }}
  //   >
  //     reset
  //   </Button>
  // )

  if (!habitToEdit) return <Element />

  return (
    <Backdrop>
      <Element visible={!!habitToEdit}>
        <Field>
          <Label htmlFor="name">Name</Label>
          <Input
            id="name"
            defaultValue={habitToEdit.habit}
            onChange={renameHabit}
          />
        </Field>

        <Field>
          <Label htmlFor="timeplace">Time + Place</Label>
          <Input
            id="timeplace"
            key={habitToEdit.habit + '-time'}
            placeholder="Time: like 8 AM"
            onChange={onTimeUpdate}
            defaultValue={habitToEdit.time}
          />
        </Field>

        <Field>
          <Label>Days</Label>
          <Days key={habitToEdit.habit + '-days'}>
            {days.map(day => (
              <DayElement
                size="small"
                key={day}
                onClick={_ => onDayToggle(day)}
                selected={habitToEdit.days.includes(day)}
              >
                {day}
              </DayElement>
            ))}
          </Days>
        </Field>

        <Actions>
          <Button appearance="delete" onClick={onDelete}>
            Delete
          </Button>
          <LongPress
            time={1000}
            onLongPress={function() {
              localStorage.clear()
              window.location.reload()
            }}
            onPress={close}
          >
            <Button appearance="subtle">Close</Button>
          </LongPress>
        </Actions>
      </Element>
    </Backdrop>
  )
}

export default EditHabit

const Backdrop = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
`

const Element = styled.div`
  position: fixed;
  z-index: 3;
  width: calc(100% - 20px);
  height: auto;
  padding: 10px;
  border-top: 2px solid #f7f7f7;
  background: #fff;
  border-radius: 10px 10px 0 0;
  box-shadow: 0 -4px 8px 1px rgba(0, 0, 0, 0.1);

  bottom: ${props => (props.visible ? 0 : '-520px')};
  transition: all 0.25s ease-out;
`

const Field = styled.div`
  margin: 10px 0 20px;
`

const Days = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 100px;
`

const Actions = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;

  display: flex;
  padding: 20px 0;
  > * {
    flex-grow: 1;
  }
`
