import getNearbyDates from './nearby-dates'
const today = getNearbyDates.today

const getStreak = ({ logs, selectedDate, habit }) => {
  /* catch null date*/
  if (!selectedDate) return 0

  /* If it's a future date, just show today's streak */
  if (selectedDate.full > today.full) selectedDate = today

  /* Filter for this habit */
  const habitLogs = logs.filter(row => row.habit.id === habit.id)

  /*
    Function to know if habit was completed
    on a specific date
  */
  const wasCompleted = date => {
    return habitLogs.find(log => log.date.full === date.full)
  }

  /* make exception for summary*/
  if (selectedDate.summary) return habitLogs.length
  /* end of exception */

  /* past dates for this habit */
  const dates = getNearbyDates()
    .filter(date => date.full <= selectedDate.full)
    .filter(date => habit.days.includes(date.day))

  const index = dates.map(date => date.full).indexOf(selectedDate.full)

  /* get previous days of selected date */
  const previousDay = dates[index - 1]

  if (wasCompleted(selectedDate)) {
    return 1 + getStreak({ logs, selectedDate: previousDay, habit })
  }

  if (previousDay && wasCompleted(previousDay)) {
    return getStreak({ logs, selectedDate: previousDay, habit })
  }

  return 0
}

export default getStreak
