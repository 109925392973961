import React, { useContext } from 'react'
import styled from 'styled-components'
import LongPress from 'react-long'
import Context from '../../context'
import { getStreak, getNearbyDates } from '../../utils'
import { Flex } from '../library'

const Habit = ({ habit }) => {
  const { selectedDate, logs, logDispatch, setHabitToEdit } = useContext(
    Context
  )

  const completed = logs.find(entry => {
    if (selectedDate.summary) return true

    return (
      entry.date.full === selectedDate.full && entry.habit.habit === habit.habit
    )
  })

  const fallsOnToday =
    !habit.days.length || habit.days.includes(selectedDate.day)

  const inFuture = selectedDate.full > getNearbyDates.today.full

  const disabled = inFuture || !fallsOnToday

  const streak = getStreak({ logs, selectedDate, habit })

  const toggle = () => {
    if (disabled) return

    let type
    if (!completed) type = 'add'
    else type = 'remove'

    logDispatch({ type, selectedDate, habit })
  }

  // hide if the habit doesn't happen on this day
  let hide = !habit.days.includes(selectedDate.day)

  // show all habits for summary
  if (selectedDate.summary) hide = false

  return (
    <LongPress
      time={500}
      onLongPress={_ => setHabitToEdit(habit)}
      onPress={toggle}
    >
      <Habit.Element completed={completed} disabled={disabled} hide={hide}>
        <Flex direction="column">
          <Name>{habit.habit}</Name>
          <Time completed={completed}>
            {fallsOnToday ? habit.time : habit.days.join(' ')}
          </Time>
        </Flex>
        <Streak>{streak}</Streak>
      </Habit.Element>
    </LongPress>
  )
}

export default Habit

Habit.Element = styled.div`
  height: calc(90px - 40px);
  padding: 20px;
  margin: 20px;
  line-height: 1.2;

  border: 2px solid;
  border-radius: 10px;

  background: ${props => (props.completed ? '#638EE1' : '#fff')};
  border-color: ${props => (props.completed ? '#638EE1' : '#e1e1e1')};
  color: ${props => (props.completed ? '#fff' : 'inherit')};

  display: ${props => (props.hide ? 'none' : 'flex')};
  justify-content: space-between;
  align-items: center;
  opacity: ${props => (props.disabled && !props.completed ? 0.3 : 1)};

  &.sorting {
    box-shadow: 0 3px 6px #d8d8d894;
  }
`

const Name = styled.span``

const Time = styled.span`
  font-size: 0.9em;
  color: ${props => (props.completed ? '#d3e3ff' : '#888')};
`

const Streak = styled.span`
  font-size: 1.5em;
  font-style: italic;
  font-weight: 900;
`
