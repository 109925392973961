import React, { useContext, useEffect, useRef } from 'react'
import Context from '../../context'
import { Day as DayElement } from '../library'

const Day = props => {
  const { selectedDate, setSelectedDate, logs } = useContext(Context)
  const selectedElement = useRef()
  const { date } = props

  useEffect(() => {
    if (selectedElement.current) {
      selectedElement.current.scrollIntoView({ behavior: 'smooth' })
    }
  })

  const isSelected = date => date.full === selectedDate.full

  return (
    <DayElement
      selected={isSelected(date)}
      highlighted={hasLogs(logs, date)}
      onClick={event => setSelectedDate(date)}
      ref={isSelected(date) ? selectedElement : null}
    >
      <div>{date.day}</div>
      <div>{date.number}</div>
    </DayElement>
  )
}

export default Day

const hasLogs = (logs, date) => {
  return logs.find(entry => entry.date.full === date.full)
}
