import arrayMove from 'array-move'
import { cloneDeep } from 'lodash'
import { getId } from './utils'

const allTheDays = ['Su', 'M', 'Tu', 'W', 'Th', 'F', 'Sa']

const initialLog = JSON.parse(localStorage.logs || JSON.stringify([]))
const initialHabits = JSON.parse(localStorage.habits || JSON.stringify([]))

const logsReducer = (logs, action) => {
  switch (action.type) {
    case 'reset':
      return initialLog
    case 'add':
      const updated = logs.concat({
        date: action.selectedDate,
        habit: action.habit
      })
      localStorage.logs = JSON.stringify(updated)
      return cloneDeep(updated)
    case 'remove':
      const index = logs.findIndex(entry => {
        return (
          entry.date.full === action.selectedDate.full &&
          entry.habit.id === action.habit.id
        )
      })

      if (index !== -1) logs.splice(index, 1)

      localStorage.logs = JSON.stringify(logs)
      return cloneDeep(logs)
    default:
      return cloneDeep(logs)
  }
}

const habitsReducer = (habits, action) => {
  let habit, index

  if (action.habit) {
    habit = habits.find(entry => entry.id === action.habit.id)
  }

  switch (action.type) {
    case 'reset':
      return []
    case 'add':
      const updated = habits.concat({
        id: getId(),
        habit: action.habit,
        days: allTheDays
      })

      localStorage.habits = JSON.stringify(updated)
      return cloneDeep(updated)

    case 'toggle_day':
      index = habit.days.indexOf(action.day)
      if (index === -1) habit.days.push(action.day)
      else habit.days.splice(index, 1)

      habit.days = habit.days.sort(
        (a, b) => allTheDays.indexOf(a) - allTheDays.indexOf(b)
      )

      localStorage.habits = JSON.stringify(habits)
      return habits

    case 'update_time':
      habit.time = action.time

      localStorage.habits = JSON.stringify(habits)
      return habits

    case 'remove':
      index = habits.findIndex(entry => {
        return entry.id === action.habit.id
      })

      if (index !== -1) habits.splice(index, 1)

      localStorage.habits = JSON.stringify(habits)
      return cloneDeep(habits)

    case 'reorder':
      habits = arrayMove(habits, action.oldIndex, action.newIndex)

      localStorage.habits = JSON.stringify(habits)
      return habits

    case 'rename':
      habit.habit = action.name

      localStorage.habits = JSON.stringify(habits)
      return cloneDeep(habits)

    default:
      return habits
  }
}

export { habitsReducer, initialHabits, logsReducer, initialLog }
