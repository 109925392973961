import React, { useState, useReducer } from 'react'
import { Dates, Habits, EditHabit } from './components'
import { getNearbyDates } from './utils'
import Context from './context'
import {
  habitsReducer,
  initialHabits,
  logsReducer,
  initialLog
} from './reducers'
import './App.css'

const dates = getNearbyDates()
const today = dates.find(date => date.isToday)

let habitCount = Infinity

const App = () => {
  const [selectedDate, setSelectedDate] = useDate()
  const [logs, logDispatch] = useReducer(logsReducer, initialLog)
  const [habits, habitsDispatch] = useReducer(habitsReducer, initialHabits)
  const [habitToEdit, setHabitToEdit] = useState()

  /* super weird logic to open edit mode for new habit */
  if (habits.length > habitCount) setHabitToEdit(habits[habits.length - 1])
  habitCount = habits.length

  return (
    <Context.Provider
      value={{
        dates,
        selectedDate,
        setSelectedDate,
        habits,
        habitsDispatch,
        logs,
        logDispatch,
        habitToEdit,
        setHabitToEdit
      }}
    >
      <Dates />
      <Habits />
      <EditHabit />
    </Context.Provider>
  )
}

export default App

const useDate = () => {
  const [selectedDate, setSelectedDate] = useState(today)
  return [selectedDate, setSelectedDate]
}
