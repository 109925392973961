import React, { useContext } from 'react'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import Habit from './habit'
import NewHabit from './new'
import Context from '../../context'
import { Image, Flex } from '../library'

const SortableItem = SortableElement(props => {
  return <Habit habit={props.habit} />
})

const SortableList = SortableContainer(({ habits }) => {
  return (
    <React.Fragment>
      {habits.length ? null : (
        <Flex justify="center" style={{ marginBottom: '-21px' }}>
          <Image src="running.png" />
        </Flex>
      )}

      <div>
        {habits.map((habit, index) => (
          <SortableItem key={index} index={index} habit={habit} />
        ))}
      </div>
    </React.Fragment>
  )
})

const Habits = props => {
  const { habits, habitsDispatch } = useContext(Context)

  const onSortEnd = ({ oldIndex, newIndex }) => {
    habitsDispatch({ type: 'reorder', oldIndex, newIndex })
  }

  return (
    <div>
      <SortableList
        habits={habits}
        pressDelay={100}
        onSortEnd={onSortEnd}
        helperClass="sorting"
        lockAxis="y"
      />
      <NewHabit />
    </div>
  )
}

export default Habits
