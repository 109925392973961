const days = ['Su', 'M', 'Tu', 'W', 'Th', 'F', 'Sa']

const decorate = raw => {
  const date = new Date(raw + ' GMT+0')
  return {
    number: date.getDate(),
    day: days[date.getDay()],
    full: date.toISOString().split('T')[0],
    isToday:
      date.toISOString().split('T')[0] ===
      new Date().toISOString().split('T')[0]
  }
}

const today = new Date()

const getNearbyDates = () => {
  const dates = []
  window.dates = dates

  const pastLimit = new Date('2019-01-01')

  let date = pastLimit

  while (date < today) {
    dates.push(decorate(date))
    date.setDate(date.getDate() + 1)
  }

  const futureDates = 2
  for (let i = 1; i <= futureDates; i++) {
    let date = new Date()
    date.setDate(today.getDate() + i)
    dates.push(decorate(date))
  }

  /* summary */
  dates.push({
    number: 2019,
    day: '.',
    full: 2019,
    isToday: false,
    summary: true
  })

  return dates
}

getNearbyDates.today = decorate(today)

export default getNearbyDates
