import React, { useContext } from 'react'
import styled from 'styled-components'
import { takeRight } from 'lodash'
import Context from '../../context'

import Day from './day'

const Dates = props => {
  const { dates } = useContext(Context)

  return (
    <Container>
      <Scroller>
        {takeRight(dates, 7 + 4).map(date => (
          <Day date={date} key={date.full} />
        ))}
      </Scroller>
    </Container>
  )
}

export default Dates

const Container = styled.div`
  width: 100%;
  padding: 20px 0;
  overflow: scroll;
`

const Scroller = styled.div`
  white-space: nowrap;
`
