import React, { useContext, useRef } from 'react'
import styled from 'styled-components'
import Context from '../../context'
import Habit from './habit'
import { Button, Input } from '../library'

const NewHabit = () => {
  const { habitsDispatch } = useContext(Context)
  const inputRef = useRef()

  const onClick = event => {
    if (!inputRef.current.value) return
    habitsDispatch({ type: 'add', habit: inputRef.current.value })
    inputRef.current.value = ''
  }
  return (
    <Element>
      <Input
        ref={inputRef}
        style={{ border: 'none' }}
        placeholder="Add new habit"
      />
      <Button appearance="icon" onClick={onClick}>
        +
      </Button>
    </Element>
  )
}

export default NewHabit

const Element = styled(Habit.Element)`
  padding: 0;
  height: 87px;
  &:focus-within {
    background: #f7f7f7;
  }
  > * {
    height: calc(100% - 2px); /* borders */
  }
`
