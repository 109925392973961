import styled, { css } from 'styled-components'
import posed from 'react-pose'

const background = {
  delete: 'transparent',
  icon: 'transparent',
  subtle: 'transparent',
  default: '#e1e1e1'
}

const color = {
  delete: 'red',
  subtle: '#888',
  default: 'inherit'
}

const Button = styled.button`
  background: ${props => background[props.appearance]};
  color: ${props => color[props.appearance]};
  border: none;
  padding: 0 20px;
  ${props => (props.appearance === 'icon' ? IconButtonStyles : null)};

  &:focus {
    outline: none;
    color: #638ee1;
  }
`
Button.defaultProps = {
  appearance: 'default'
}

const IconButtonStyles = css`
  font-size: 1.5em;
  font-weight: 700;
`

const Label = styled.label`
  color: #888;
  padding: 5px;
`

const Input = styled.input`
  display: block;
  background: transparent;
  border: 1px solid #e1e1e1;
  padding-left: 20px;
  border-radius: 10px;
  min-height: 60px;
  width: calc(100% - 20px);
  &:focus {
    outline: none;
    background: #f7f7f7;
  }
  &:disabled {
    opacity: 0.2;
  }
`

const Flex = styled.div`
  display: flex;
  flex-direction: ${props => props.direction};
  justify-content: ${props => props.justify};
`

Flex.defaultProps = {
  direction: 'row',
  justify: 'space-between'
}

const Day = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  width: ${props => (props.size === 'small' ? '40px' : '75px')};
  height: ${props => (props.size === 'small' ? '40px' : '75px')};
  margin: 0 ${props => (props.size === 'small' ? '5px' : '10px')};
  border: 2px solid;

  background: ${props => (props.selected ? 'transparent' : '#f7f7f7')};
  border-color: ${props => (props.selected ? '#638ee1' : '#f7f7f7')};
  color: ${props => (props.highlighted ? '#638ee1' : '#888')};

  border-radius: 10px;
  text-align: center;

  div:first-child {
    font-size: 0.7em;
  }
`

const Drawer = posed.div({
  visible: {
    opacity: 1,
    transition: { duration: 300 }
  }
})

const Image = styled.img``

export { Button, Label, Input, Flex, Day, Drawer, Image }
